.mainimg {
  width: 320px;
  height: 320px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.dogsmall {
  width: 160px;
  height: 160px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
}

.subimg {
  width: 200px;
  height: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.imgcontainer {
  width: 100%;
  align-content: center;
  align-items: center;
}

.title {
  font-family: 'Cormorant SC';
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 48px;
  text-align: center;
  margin-top: 48px;
  margin-bottom: 24px;
}

.nextButton {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;
  font-family: 'Cormorant SC';
  font-size: 48px;
  text-align: center;
  padding: 12px;
}

.nextButtonSmall {
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Cormorant SC';
  font-size: 24px;
  text-align: center;
  padding: 4px;
  padding-left: 18px;
  padding-right: 18px;
}

.flexbox {
  display: flex;
}

.stickflexbox {
  display: flex;
}

.col-50 {
  flex: 50%;
}

.col-70 {
  flex: 70%;
}

.col-30 {
  flex: 30%;
}

.rules {
  font-family: 'Cormorant SC';
  font-size: 24px;
}

.note-text {
  font-family: 'Cormorant SC';
  font-size: 24px;
  text-align: center;
}

.container {
  display: block;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
}

.bullet {
  margin-bottom: 16px;
}

.bonerating {
  font-family: 'Cormorant SC';
  font-size: 36px;
  display: inline-block;
  margin: 0px;
}

.boneimg {
  width:80px;
  display: inline-block;
  margin: 0px;
}

.center {
  display: block;
  width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.inline {
  display: inline-block;
}

.buttoncontainer {
  text-align: center;
  margin-top: 24px;
}

.imgsmall {
  width: 80px;
  height: 80px;
  display: block;
}

.boneratingsmall {
  font-family: 'Cormorant SC';
  font-size: 18px;
  display: inline-block;
  margin: 0px;
}

.boneimgsmall {
  width:40px;
  display: inline-block;
  margin: 0px;
}

.transparent {
  opacity: 0.8
}

.absolute-top {
  position: absolute;
  top: 5%;
}

.absolute-left {
  left: 10%;
}

.text-center {
  text-align: center;
}

.conclusionimg {
  width: 120px;
  height: 120px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
}

.marginbottom {
  margin-bottom: 8px;
}

@media only screen and (max-width: 600px) {
  .container {
    display: block;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .mainimg {
    width: 200px;
    height: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .flexbox {
    display: block;
  }
  

  .title {
    font-family: 'Cormorant SC';
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 36px;
    text-align: center;
    margin-top: 36px;
    margin-bottom: 24px;
  }

  .subimg {
    width: 100px;
    height: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .absolute-top {
    position: relative;
  }

  .stickflexbox {
    width: 100%;
  }

  .stickitem {
    display: inline;

  }
}